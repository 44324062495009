/* General */
@font-face {
  font-family: "Heroes Legend";
  src: url("HeroesLegend.ttf");
  font-display: swap;
  }

body{
  margin: 0;
  overflow-x: hidden;
}

img{
  height: 90%;
  padding: 5px;
  max-width: 95%;
}

.heroes{
  font-family: "Heroes Legend";
  font-size: 1em;
  margin: 0;
  margin-bottom: 20px;
  color: white;
  line-height: 2;
  baseline-shift: -2px;
}

table tr td{
  font-size: 0.8em;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fH{
  font-family: Heroes Legend;
}

.hidden{
  display: none;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.selector select::-ms-expand{
  display: none;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.selector select{
  background: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  margin: 0;
  width: 80px;
  color: #7fffd4;
  text-align: center;
}

.double{
  font-size: 2em;
}

.page{
  margin-top: 12vmax;
  margin-bottom: 12vmax;
}

.zoom{
  transition: transform .2s;
}

.htitle, .titlePage > h1{
  margin: 0;
}
.titlePage > h1{
  font-size: 2em;
}
.titlePage{
  display: flex;
  align-items: center;
}

/*  */
/* Top Tickets */
.topTickets, .topCommands.info{
  background-color: rgba(41,45,53,.9);
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.topTickets > table > tr.trtitulo > td{
  padding: 5px;
}

.topTickets .navTops, .topCommands .navTops{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.btnForallia{
  max-width: 50%;
}

.topCommands .navTops img{
  width: 5.5vmax;
  height: auto;
}

.topCommands .navTops a{
  display: flex;
  align-items: center;
}

.topTickets .navTops > div, .btnForallia, .topCommands .navTops > div{
  padding: 10px;
  margin: 5px 5px;
  border: rgb(136, 138, 137) solid 1px;
  background-color: #515452;
  cursor: pointer;
}

.topTickets .navTops > div.active, .btnForallia.active{
  background: linear-gradient(137deg, rgba(0,0,0,1) 0%, rgba(158,158,158,1) 84%, rgba(94,94,94,1) 100%);
}

.topTickets .navTops > div:active, .btnForallia:active{
  background: black
}

/*  */
/* Redes */
.redes > .card > a{
  display: flex;
  justify-content: center;
}
.redes > .card > a > img{
  max-height: 150px;
}
.redes > .card > a > img:hover{
  max-height: 150px;
}
.redes > .card{
  max-width: fit-content;
}
.redes, .commandsBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*  */
/* Countdown */
.countdown h2{
  font-size: 15px
}
/*  */
/* Info Page */
.info, .commandsBox{
  background: none;
}
/*  */
/* CommandsBox */
.infoBox{
  display: inline-flex;
  flex-flow: wrap;
  justify-content: center;
}
.infoBox > div{
  display: inline-flex;
  min-width: 95%;
  max-width: 95%;
  margin: 10px;
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom right, #008f18 50%, #fa1700 50%);
  border-image-slice: 1;
  background: #181717;
  text-align: left;
}
.infoBox > div:hover{
  background-image: linear-gradient(90deg, rgba(255, 0, 0, 0.7), rgba(0, 255, 0, 0.7));
  cursor: pointer;
  z-index: 900;
}
.infoBox > div > div{
  flex: 1;
}
.infoBox img{
  max-width: 50px;
  max-height: 50px;
}
.infoBox .commandRow img{
  max-height: 2em;
  vertical-align: sub;
  padding: 0;
}
.infoBox .rewardImage{
  min-height: 100px;
  min-width: 100px;
}
.infoBox .description{
  font-size: 0.8em;
  letter-spacing: 1px;
  padding: 4px;
}
.infoBox .commandRow{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2px;
  letter-spacing: 1px;
}
/*  */
/* miniTops */
.infoBoxHome > div{
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom right, #008f18 50%, #fa1700 50%);
  border-image-slice: 1;
  background: #181717;
  text-align: left;
}

.infoBoxHome > div > h3{
  text-align: center;
}
.infoBoxHome > div > div{
  display: flex;
  justify-content: space-between;
}
.infoBoxHome > div > div > div{
  display: flex;
  align-items: center;
  max-height: 30px;
  max-width: 32%;
  flex: 1;
  justify-content: center;
}
/*  */
.calendarwaifu{
  background-image: url("./views/images/calendarwaifu.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 5em;
  color: aquamarine;
  justify-content: space-around;
  flex-direction: column;
  display: flex;
}

html{
  background-color: #181a1f;
  color: white;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
}

table {
  border-collapse: collapse;
}
tr{
  border-bottom: 3px solid white;
}
strong,.trtitulo{
  color: aquamarine;
}

.Logo,.Logo img{
  height: 90%;
}
.infoTwitch{
  padding-top: 35px;
}
.infoTwitch img{
  max-height: 3em;
}
.infoTwitch > span{
  display: flex;
  align-items: center;
}
.header{
  background-color: #292d35;
  top: 0;
  width: 100%;
  height: 5vmax;
  z-index: 100;
  position: fixed;
  text-align: center;
}
@keyframes move {
  0% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(10px);
  }
  100% {
      transform: translateX(0);
  }
}
img.over:not(:active) {
  animation: move 3s infinite;
  animation-timing-function: ease-in-out;
}

a{
  height: 100%;
  color: white;
}
a:link, a:visited, a:active {
  text-decoration:none;
}

ul,p{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
  margin-bottom: 1vmax;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

li:hover{
  background-color: #454a53;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
iframe{
  width: 100%;
  height: 60%;
}



#menu-main{
  padding: 8px 0;
  overflow: hidden;
  background: #515452;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  border-top: 1px solid rgb(136, 138, 137);
  box-shadow:rgb(136, 138, 137);
  /* Aplicamos Flex */
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
}
#primary,#son{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#menu-main img{
  width: 6vmax;
  margin: 0;
  display: block;
}


.menu-button {
  display: block;
  width: 4vmax;
  margin: 1vmax;
  position: absolute;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.menu-button:before,
.menu-button:after {
  background-color: rgb(255, 255, 255);
  content: '';
  display: block;
  height: 3px;
}
.menu-button:before {
  box-shadow: 0 10px 0 rgb(255, 255, 255);
  margin-bottom: 16px;
}

.core{
  margin-top: 12vmax;
  display: flex;
  flex-direction: column;
}
.core .main, .social-links{
  display: flex;
  justify-content: center;
}
.core .main .child{
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: initial;
}
.core .main .child span{
  padding: 5px;
}
.home.core > div:last-child{
  margin-bottom: 10vmax;
}
.playerName{
  line-height: 2;
  text-align: center;
  padding: 20px 0;
  font-family: "Heroes Legend";
  font-size: initial;
}

.TarjetaNoticia:hover{
  background-color: rgb(9, 0, 128);
  border-color: red;
}

.TarjetaNoticia{
  background-color: rgb(31, 28, 44);
  height: 17vmax;
  width: 18vmax;
  text-align: center;
  display: inline-block;

  position: relative;
  border-width: 3px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  font-size: 1vmax;
}

.TarjetaNoticia img {
  height: 12vmax;
  width: 18vmax;
  margin-left: auto;
  margin-right: auto;
  display:block;
}
.right{
  text-align: right;
  padding-right: 2em;
}
.centrar{
  text-align: center;
  margin-top: 6vmax;
}

.noticia{
  height: 20vmax;
}

.noticia p{
  font-size: 1vmax;
}
.reglas,.facciones,.historia{
  display: flex;
  flex-direction: column;
  right: 8vmax;
  left: 40vmax;
  top: 8vmax;
  position: absolute;
  background-color: rgb(41, 45, 53,0.9);
  height: 70vh;
  width: 50vw;
  padding: 15px;
  min-height: 240px;
  min-width: 320px;
  text-align: center;
}
.reglas h1{
  font-size: 48px;
}
.reglas ul li{
  font-size: 24px;
}

#minecraft{
  background-image: url(https://i.redd.it/x6x31f3agf851.jpg);
  background-size: cover;
  margin-left: 5vmax;
  height: 100vh;
  background-position: center;
}


#minecraft #menu,#sorteo #menu{
  display: flex;
  right: 8vmax;
  left: 8vmax;
  top: 8vmax;
  position: fixed;
  background-color: rgb(41, 45, 53,0.9);
  height: 19vmax;
  width: 40vmax;
  padding: 15px;
  min-width: 320px;
  max-width: 383px;
  max-height: 420px;
  min-height: 240px;
}
#sorteo #menu{
  flex-direction: column;
  justify-content: space-around;
  max-height: 350px;
  min-height: 270px;
}
#hide{
  display: flex;
  justify-content: center;
}
#sorteo{
  background-image: url(https://i.ibb.co/nzJ7Rpn/random.png);
  background-size: 300px, contain;
  height: 100vh;
}

.ico{
  height: 48px;
}
#reglas{
  text-align: center;
  height: 35px;
  display: block;
  padding: 18px 25px;
  
  
}
#reglas:hover{
  background-color: rgb(62, 89, 145);
}
.lore{
  font-size: 13px;
  margin-bottom: 15px;
}
#menudesplegable,#menudesplegableon{
  background-image: url(./views/images/grid.png);
  background-size: 32px;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  margin-left: 15px;
  margin-top: 15px;
  position: absolute;
  cursor: pointer;
  display: none;
}
#menudesplegable:active{
  background-image: url(./views/images/gridon.png);
}
.character{
  margin-top: 15px;
  width: 125px;
  display: none;
}
.character img{
  padding: 0;
}
#health{
  appearance: none;
  -webkit-appearance: none;
  margin: auto;
  height: 18px;
  width: 70%;
}
#health:before {
  content: attr(data-label);
  font-size: small;
  vertical-align: 0;
  font-weight: bold;
  margin-left: 5px;
  position: absolute;
}
#health[value]::-webkit-progress-value {
  background-image:
	   -webkit-linear-gradient(-45deg, 
	                           transparent 33%, rgba(0, 0, 0, .1) 33%, 
	                           rgba(0,0, 0, .1) 66%, transparent 66%),
	   -webkit-linear-gradient(top, 
	                           rgba(255, 255, 255, .25), 
	                           rgba(0, 0, 0, .25)),
	   -webkit-linear-gradient(left, rgb(255, 0, 0), rgb(26, 190, 21));
    border-radius: 2px; 
    background-size: 35px 20px, 100% 100%, 100% 100%;
}
#health[value]::-moz-progress-bar {
  background-image:
    -moz-linear-gradient(-45deg, 
                          transparent 33%, rgba(0, 0, 0, .1) 33%, 
                          rgba(0,0, 0, .1) 66%, transparent 66%),
    -moz-linear-gradient(top, 
                          rgba(255, 255, 255, .25), 
                          rgba(0, 0, 0, .25)),
    -moz-linear-gradient(left, rgb(255, 0, 0), rgb(26, 190, 21));
  border-radius: 2px; 
  background-size: 35px 20px, 100% 100%, 100% 100%;
}
/* INPUT USER*/
.userSearch{
  margin-top: 15px;
  background: #515452;
  color: white;
  font-family: Heroes Legend;
  text-align: center;
  border-color: #292d35;
  border-radius: 5px;
  height: 45px;
  align-self: center;
  width: 80%;
  font-size: 16px;
}
.userResults{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.userResults div{
  border: 1px solid #515452;
    height: 90px;
    width: 95%;
    border-radius: 5px;
    margin: 1px;
}
.userResults > div:last-child{
  margin-bottom: 90px;
}
.userResults div:active{
  background-color: #515452;
}
.userResults div a{
  display: flex;
}
.userResults div.previewAvatar{
  width: 30%;
  border: none;
}
.previewData, .flex{
  display: flex;
  flex-direction: column;
}
.flex {
  width: 100%;
  align-items: center;
  min-width: -webkit-fill-available;
}
.userResults div.previewDisplayname{
  font-family: Heroes Legend;
  height: initial;
  text-align: center;
  border: none;
  padding-top: 5px;
}
.userResults div.previewExtra{
  display: flex;
    font-family: Heroes Legend;
    font-size: smaller;
    align-items: center;
    border: none;
}
.userLink{
  font-weight: bold;
  color: transparent; /* El color inicial es transparente */
  background-image: linear-gradient(to right, red, green);
  background-clip: text;
  -webkit-background-clip: text; /* Necesario para compatibilidad en WebKit (Chrome, Safari) */
  transition: background-position 0.5s ease; /* Animación suave del fondo */
  background-size: 200% 100%; /* Tamaño del fondo para la animación */
  background-position: left; /* Posición inicial del fondo */
}
.userLink:hover {
  background-position: right; /* Mueve el fondo hacia la derecha al hacer hover */
}
.villano{
background: rgb(255,255,255);
background: -moz-linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,0,0,1) 100%);
background: -webkit-linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,0,0,1) 100%);
background: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ff0000",GradientType=1);
}
.villanoString{
  color: rgba(255,0,0,1);
}
.heroe{
background: rgb(255,255,255);
background: -moz-linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(0,168,78,1) 100%);
background: -webkit-linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(0,168,78,1) 100%);
background: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(0,168,78,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00a84e",GradientType=1);
}
.heroeString{
  color: rgba(0,168,78,1);
}
.corruptos{
  background: rgb(255,255,255);
  background: -moz-linear-gradient(135deg, rgb(255, 0, 0) 0%, rgba(0,168,78,1) 100%);
  background: -webkit-linear-gradient(135deg, rgb(255, 2, 2) 0%, rgba(0,168,78,1) 100%);
  background: linear-gradient(135deg, rgb(255, 0, 0) 0%, rgba(0,168,78,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00a84e",GradientType=1);
  }
.userResults div.previewExtra div{
  height: initial;
  border: none;
}
.userResults div.previewData{
  border: none;
}

.PopUp {
  min-width: 95%;
  max-width: 95%;
  margin: 10px;
  border: 3px solid transparent;
  border-image: linear-gradient(to bottom right, #008f18 50%, #fa1700 0);
  border-image-slice: 1;
  background: #181717;
  text-align: left;
  z-index: 500;
  position: absolute;
}

.PopUp .PopUpTitle, .PopUp .PopUpDescription{
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(to top right, #008f18 50%, #fa1700 0);
  border-image-slice: 50;
  display: flex;
  align-items: center;
}
.PopUp .PopUpTitle > img{
  max-height: 80px;
}

.PopUp .PopUpDescription{
  font-size: smaller;
  padding: 10px;
}
.PopUp .PopUpVideo{
  display: flex;
}
.PopUp .PopUpClose{
  color: red;
  right: 10px;
  position: absolute;
  z-index: 700;
  top: 5px;
  cursor: pointer;
}
.PopUp .PopUpVideo > div{
  min-height: 80px;
  min-width: 80px;
  max-height: 80px;
  max-width: 80px;
  border-right: 1px solid transparent;
  border-image: linear-gradient(to bottom right, #008f18 100%, #fa1700 0);
  border-image-slice: 15;
}
.PopUp .PopUpVideo > div > a{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sorteoComp{
  border: 1px solid transparent;
  border-image: linear-gradient(to top right, #008f18 50%, #fa1700 0);
  border-image-slice: 50;
  height: 60px;
  padding-top: 12px;
}
.fullCenter{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Droppables */
.droppableUserSearch{
  width: 100%;
  display: flex;
  justify-content: center;
}
/*  */

/* Animation loop */
.loopText {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.loopText span {
  display: inline-flex;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  align-items: center;
}

.loopText img {
  max-width: 66px;
}

@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-150%); }
}
/*  */

/* Spinner */
.preSpinner {
  height: 10vh;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  align-items: center;
}
.spinner {
  border: 4px solid rgb(255, 0, 0);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: green;

  animation: spin 1s ease infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*  */
/* InfoExtender */
.infoExtender{
  cursor: pointer;
}
#infoExtendMenu{
  padding: 8px 0;
  overflow: hidden;
  background: #515452;
  position: fixed;
  bottom: 0vmax;
  width: 100%;
  z-index: 99;
  border-top: 1px solid rgb(136, 138, 137);
  box-shadow:rgb(136, 138, 137);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  transition: bottom 0.2s ease-in-out;
  pointer-events: none;
}
#infoExtendMenu img{
  width: 6vmax;
  margin: 0;
  display: block;
}
#infoExtendMenu.show{
  pointer-events: all;
  bottom: 9vmax;
}
/*  */
/* Twitch Button */
.btnTwitch{
  border-top: 1px solid rgb(136, 138, 137);
  width: 80%;
  display: flex;
  background-color: #9146ff;
  height: 35px;
  justify-content: center;
  align-items: center;
  align-self: center;
}
/*  */

/* accountImage */
.accountImageBorder{
  padding: 5px; /* Espacio entre la imagen y el borde */
  background: linear-gradient(red, green); /* Degradado de rojo a verde */
  border-radius: 10px;
}
.accountImageBorder img{
  display: block;
  border-radius: 8px; /* Para que el borde siga el mismo radio de la imagen */
  background: white;
}
/*  */

.socialImage{
  padding: 0;
  padding-left: 5px;
  min-width: 27px;
  position: relative;
  z-index: 98;
}
.home .socialImage{
  margin-top: -5px;
}
.social-links .socialImage{
  max-width: 43px;
  max-height: 43px;
}
#participantes a, #hallFama a, #gamers a{
  vertical-align: middle;
}
#participantes .socialImage, #hallFama .socialImage, #gamers .socialImage{
  max-width: 27px;
  max-height: 27px;
}
table tr td > div{
  display: inline-flex;
}

/* Rewards */
.economyBox{
  z-index: 99;
  position: fixed;
  top: 9.3vmax;
  width: 70px;
  height: 40px;
  background-color: #292d35;
  display: flex;
  flex-direction: column;
  right: 10px;
  justify-content: center;
  animation: moveLeftAndRight 3s ease-in-out infinite;
  padding: 0 5px;
}
.economyBox > .economyMenu{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.economyBox > .economyMenu img{
  width: 27px;
  height: 27px;
  padding: 0px;
  margin-right: 5px;
  display: inline;
}
.economyBox > .economyMenu span{
  margin: 0;
  font-size: 11px;
  line-height: 11px;
  vertical-align: middle;
}
/*  */
@keyframes moveLeftAndRight {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(5px);
  }
  20% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
/*  */
@media (min-width: 0px) and (max-width: 1000px){
        .responsive{
          display: none;
        }
        .header{
          height: 10vmax;
        }
        .menu-button {
          width: 8vmax;
        }
        .nucleo{
          margin-left: 10vmax;
          display: inline-block;
        }
        .centrar{
          margin-top: 10vmax;
        }
        .noticia{
          height: 15vmax;
        }
        .noticia p{
          font-size: 2vmax;
        }
        .oraculo{
          margin-top: 10vmax;
        }
        #minecraft{
          margin-top: 10vmax;
          margin-left: 10vmax;
          height: 150vh;    
        }
        #minecraft #menu, #sorteo #menu{
          left: 13vmax;
          top: 13vmax;
          width: 50vw;
          height: 30vmax;
        }
        #sorteo #menu{
          height: 40vmax;
        }
        .reglas,.facciones,.historia{
          left: 13vmax;
          top: 60vmax;
          width: 50vw;
            
        }
        .reglas h1{
          font-size: 32px;
        }
        .reglas ul li{
          font-size: 16px;
        }
        tr.trtitulo.heroes{
          font-size: 0.8em;
        }
}

@media (min-width: 0px) and (max-width: 450px){
   #minecraft{
     background-image: url(https://i.pinimg.com/originals/51/64/23/516423d8a2d73309efba8f221ab7740c.png);
    background-size: contain;
     height: 190vh;
     margin-left: 0;
     display: flex;
     justify-content: center;
   }
   .centrar,.nucleo{
     margin-left: 5px;
     margin-right: 5px;
   }

  #reglas{
    padding: 18px 5px;
  
  }
  #sorteo{
    display: flex;
    justify-content: center;
  }
  #sorteo #menu{
    left: initial;
    right: initial;
    max-height: 400px;
    height: 40vmax;
    
  }
  .reglas,.facciones,.historia,#minecraft #menu{
    min-width: 230px;
    left: initial;
    right: initial;
  }
  .reglas{
    height: 80vh;
  }
  .facciones{
    height: 115vh;
  }
  .historia{
    height: 130vh;
  }
  #menudesplegable{
    display: block;
  }
  iframe{
    width: 100%;
    height: 40%;
  }
  table tr td{
    font-size: 0.8em;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  table tr td > div{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 9em; /* Ajusta según lo que necesites */
    align-items: center;
  }
  table tr td > div > span{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  tr.trtitulo.heroes{
    font-size: 0.7em;
  }
}

/* Desktop Fixes */
@media (min-width:1078px){
  .page{
    margin-top: 6vmax;
    margin-bottom: 8vmax;
  }
  table tr td{
    font-size: initial;
  }
  .core{
    margin-top: 6vmax;
  }
  .userSearch{
    font-size: 20px;
  }
  .formOver .userSearch{
    font-size: 15px;
    height: 30px;
  }
  .infoBox img{
    max-width: 100px;
    max-height: 100px;
  }
  .infoBox .commandRow{
    flex-direction: row;
  }
  .infoBox .commandRow > .commandRow{
    flex-direction: column;
  }
  .infoBox > div {
    max-width: 50%;
    min-width: 50%;
    flex: 1;
  }

  .PopUp {
    max-width: 50%;
    min-width: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .userResults > div:last-child{
    margin-bottom: 10vmax;
  }
  #infoExtendMenu.show{
    bottom: 8vmax;
  }
  .btnTwitch{
    width: 33%;
  }
  .zoom:hover {
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    z-index: 101;
  }
  .infoBox .zoom:hover {
    transform: scale(1.1);
    z-index: 101;
  }
}